<template>
  <div class="gisMap" style="position: relative">
    <el-form ref="formValidate" class="form" :inline="true" :model="useUnit">
      <el-form-item :label="$t('monitor.area')" prop="districtID">
        <vm-district-select style="width: 280px;line-height: 32px;" v-model="useUnit.districtID" :change-on-select="true"
          @onChange="onDistrictChange"></vm-district-select>
      </el-form-item>
      <el-form-item>
        <el-button class="search" :loading="submitLoading" @click="handleSearch">
          {{ $t("common.search") }}
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="initLoading" @click="handlerInit">
          {{ $t("common.reset") }}
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="changeMap">{{ $t("monitor.change") }}</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="changeMute">{{ $t("monitor.mute") }}
        </el-button>
      </el-form-item>
    </el-form>
    <bai-du-map v-if="currencyMap" ref="changeMapRef"
      style="width: 100%;height:calc(100vh - 170px);border:#ccc solid 1px;" :city-name="cityName"></bai-du-map>
    <google-map v-else ref="changeMapRef" style="width: 100%;height:calc(100vh - 180px);border:#ccc solid 1px;"
      :city-name="cityName"></google-map>
    <img v-if="$i18n.isCn" src="/static/images/baiDuMap/shuidishuoming.png"
      style="right: 32px; bottom:25px; position: absolute;"/>
    <img v-else src="/static/images/baiDuMap/shuidishuoming_en.png"
      style="right: 32px; bottom:25px; position: absolute;"/>
    <div v-if="showAlarmTable" :style="{ 'bottom': tBottom }"
      style="transition: 0.5s;position:absolute;z-index:3;height: 470px;right: 0">
      <alarm-table @clickExpandTable="clickExpandTable"></alarm-table>
    </div>
    <audio v-if="faultAudio" id="faultMp3" src="/static/audio/Alarm.mp3" loop="loop" autoplay></audio>
  </div>
</template>

<script>
import VmDistrictSelect from "../../components/VmDistrictSelect";
import GoogleMap from "./GoogleMap.vue";
import BaiDuMap from "./BaiDuMap.vue";
import alarmTable from "./AlarmTable.vue";

export default {
  components: {
    VmDistrictSelect, GoogleMap, BaiDuMap, alarmTable,
  },
  /* eslint-disable */
  props: ["showAlarmTable"],
  data() {
    return {
      data: [],
      useUnit: {
        districtID: 0,
      },
      isSilence: false,
      faultAudio: false,
      submitLoading: false,
      initLoading: false,
      cityName: ["上海"],
      currencyMap: this.$i18n.isCn,
      tableFlag: false,
      tBottom: "-440px",
    };
  },
  mounted() {
    this.getMap();
  },
  methods: {
    changeMute() {
      this.isSilence = !this.isSilence;
    },
    getMap() {
      let params = {
        districtId: this.useUnit.districtID,
      };
      this.$api.getDataT("/gisMap", params, 100).then(res => {
        this.initLoading = false;
        this.data = res.data;
        this.$refs.changeMapRef.addMarker(this.data);
      }).catch(() => {
        this.initLoading = false;
        if (this.currencyMap) {
          this.$message.error(this.$t("common.tip.baiduMapError"));
        } else {
          this.$message.error(this.$t("common.tip.googleMapError"));
        }
      });
    },
    handlerInit() {
      this.initLoading = true;
      this.useUnit.districtID = 0;
      this.getMap();
    },
    onDistrictChange(id, name) {
      this.cityName = name;
    },
    handleSearch() {
      this.refreshData();
      setInterval(() => this.refreshData(), this.districtID === 0 ? 300000 : 300000);
    },
    refreshData() {
      let params = {
        districtId: this.useUnit.districtID,
      };
      this.submitLoading = true;
      this.$api.getDataT("/gisMap", params, 100).then(res => {
        this.submitLoading = false;
        this.data = res.data;
        console.log(this.data);
        let flag = false;
        for (let item of this.data) {
          if (item.alarmTime) {
            let alarmTime = new Date(item.alarmTime.replace(/-/g, "/"));
            let timeDiff = new Date().getTime() - alarmTime.getTime();
            if (timeDiff <= 60 * 1000) { // 存在1分钟内的
              flag = true;
            }
          }
        }
        if (flag && !this.isSilence) {
          this.tBottom = "0px";
          this.faultAudio = true;
        } else {
          this.tBottom = "-440px";
          this.faultAudio = false;
        }
        this.$refs.changeMapRef.addMarker(this.data);
      }).catch(() => {
        this.submitLoading = false;
        this.$message.error(this.$t("common.tip.getDataError"));
      });
    },
    changeMap() {
      this.currencyMap = !this.currencyMap;
      this.getMap();
    },
    clickExpandTable() {
      this.tableFlag = !this.tableFlag;
      if (this.tableFlag) {
        this.tBottom = "0px";
      } else {
        this.tBottom = "-440px";
      }
    },
  },
};
</script>
<style lang="scss">
.gisMap {

  .el-form-item {
    margin: 10px 10px 10px 0;
  }
  .el-form-item__label {
    color: #fff;
  }
  .el-button {
    padding: 0 20px;
    height: 32px;
    border-radius: 8px;
    font-size: 14px;
    border: none;
    background-color: #fff;
    color: #606266;

    &:hover {
      color: #0747FD;
    }
  }

  .search {
    background-color: #0747FD;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}
</style>
